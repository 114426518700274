/* Surcharge bootstrap */

body { background-color: #fff; }
.thumbnail { border:none; border-radius: 0; }
.btn, .form-control, .panel { border-radius: 0; }
/*.btn-primary { background-color:#f60; color:#fff; border:none; }*/
/*.btn-primary:hover, .btn-primary:focus { background-color:#e50; }*/
.btn-default:hover { color: #337ab7; background-color: #eee; }
.alert { position: absolute; top: 10px; z-index: 99; border-radius: 4px; width: 97%; }
/*a { color: #f60; }
a:focus, a:hover { color: #e50; }*/

/* Layout */
html, body { height: 100%; }
.wrapper { position: relative; background-color: #fff; overflow: hidden; min-height: 100%; padding-top: 10%; }
.strong { font-size: 20px; line-height: 20px; text-transform: uppercase; color:#363636; }
.strongest { font-size: 30px; line-height: 30px; font-weight: normal; /*color: #F60;*/ }

/* progress dialog */
#progress-dialog { position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1999; background: rgba(255, 255, 255, 1); text-align: center; padding-top: 200px; }
#progress-bar { position: relative; display: inline-block; width: 200px; height: 10px; background: rgba(0,0,0,0.1); }
.undeterminate #progress-bar { background: transparent; }
#progress-bar span { position: absolute; top: 0; left: 0; display: inline-block; width: 1px; height: 10px; background: #337ab7; }
.undeterminate #progress-bar span { right: 0; height: 10px; background: transparent url(../images/undeterminate-loader.gif) center center no-repeat; }
#progress-text { color: #777; }
#screenshots img { margin: 1em; border: 1px solid #999; }

/* sidebar */
#bing-tiles { font-size: 90%; }
#no-search { height: 15px; }
.sidebar-header { position: fixed; top: 0; left: 0; width: 310px; z-index: 9; background: #fff; box-shadow: 2px 2px 3px rgba(0,0,0,0.3); }
.sidebar-header.open { box-shadow: none; }
.sidebar-header .icon-bar { transition: all 0.2s linear; }
.sidebar-header.open .icon-bar-first { transform: translateX(-1px) translateY(2px) rotate(-45deg); width: 11px; }
.sidebar-header.open .icon-bar-third { transform: translateX(-1px) translateY(-2px) rotate(45deg); width: 11px;}
.sidebar-header .btn { border: none; }
.sidebar-header .navbar-toggle { display: block; float: none; border-radius: 0; margin: 0; padding: 8px; }
.sidebar-header .navbar-toggle:hover { background: #337ab7; }
.sidebar-header .navbar-toggle .icon-bar { background: #337ab7; }
.sidebar-header .navbar-toggle:hover .icon-bar { background: #fff; }
.map-sidebar { position: fixed; top: 0; left: 0; width: 310px; /*overflow: hidden;*/ background: #fff; z-index: 8;  }
.map-sidebar.open { bottom: 0; height: auto; padding-bottom: 32px; box-shadow: 2px 2px 3px rgba(0,0,0,0.3); }
.map-sidebar .if-open { opacity: 0; z-index: -1; transition: opacity 0.4s linear;}
#sidebar-content { position: absolute; top: 32px; bottom: 32px; left: 0; right: 0; overflow: auto; transform: translateX(-310px); transition: all 0.2s ease-in; }
.open #sidebar-content { transform: translateX(0); }
.map-sidebar.open .if-open { opacity: 1; z-index: 1; }
#search-form { padding: 15px 7.5px; }
.map-sidebar .breadcrumb { margin: 7.5px 0 0 0; background: #fff; }
.map-sidebar .breadcrumb>li+li:before { content: "›"; font-size: 150%; padding: 0 3px 0 1px; line-height: 0.66; } 
.map-sidebar .panel { border-left: none; border-right: none; margin: 0; border-bottom: none; }
.map-sidebar .panel .panel { margin: -15px; }
/*.map-sidebar .panel + .panel { border-top: none; }*/
.map-sidebar .panel-heading { border-bottom: none; border-radius: 0; position: relative; }
.map-sidebar .panel-heading > a { font-weight: bold; margin: -10px -15px; display: block; padding: 10px 15px; }
#sidebar-content .panel-heading > a:before,
#sidebar-content .panel-heading > a:after { content: ""; position: absolute; background: #CCC; top: 20px; width: 10px; height: 2px; transition: 0.2s all linear; }
#sidebar-content .panel-heading > a:before { right: 21px; transform: rotate(-45deg); }
#sidebar-content .panel-heading > a:after { right: 15px; transform: rotate(45deg); }
#sidebar-content .panel-heading > a.collapsed:before { transform: rotate(45deg); }
#sidebar-content .panel-heading > a.collapsed:after { transform: rotate(-45deg); }
.map-sidebar .panel-body { border-top: 1px solid #ddd; }
.map-sidebar .panel:last-child .panel-heading { border-bottom: 1px solid #ddd; }
.map-sidebar .panel:last-child .panel-body { border-top: none; }
div.layer-group { border-bottom: 1px solid #eee; padding-bottom: 7.5px; }
div.layer-group .toggle a { font-weight: normal; float: right; opacity: 0; transition: all 0.2s linear; }
div.layer-group .toggle a + a { margin-right: 10px; }
div.layer-group .toggle.open a { opacity: 1; }
div.layer-group .layer-group-title { cursor: pointer; position: relative; }
div.layer-group .layer-group-title.toggle { padding-left: 15px; }
div.layer-group .layer-group-title.toggle:after { content: ''; position: absolute; top: 9px; left: 0; width: 0; height: 0; border-style: solid; border-width: 5px 0 5px 7px; border-color: transparent transparent transparent #BBB; transition: all 0.2s linear; }
div.layer-group .layer-group-title.toggle.open:after { transform: rotate(90deg); }
div.layer-group .layer-group-content { display: none; }
div.layer-group:last-child { border-bottom: none; }
div.layer-group label { display: block; padding: 5px 0; margin: 0; }
div.layer-group label select, div.layer-group label select option { font-weight: normal; }
div.layer-group label.layer { padding-left: 15px; font-weight: normal; }
div.layer-group label.layer:hover { background: #f4f4f4; }

div.layer-group label .legend { display: none; float: right; margin-top: -4px; width: 32px; height: 32px; }
div.layer-group label :checked + .legend { display: block; }
div.layer-group label .legend-ce { background: url('images/ce_couleur.png'); }
div.layer-group label .legend-concessions { background: url('images/concession_bleu.png'); }
div.layer-group label .legend-garages { background: url('images/garage2_bleu.png'); }
div.layer-group label .legend-hypermarches { background: url('images/hypermarche_vert.png'); }
div.layer-group label .legend-supermarches { background: url('images/supermarche_vert.png'); }
div.layer-group label .legend-clubs_sportifs { background: url('images/sport.png'); }

div.side-menu-heading { padding: 10px 15px; color: #777; font-weight: bold; background: #f5f5f5; border-color: #ddd; border-width: 1px 0 1px 0; border-style: solid;}
div.side-menu-item a { display: block; padding: 10px 15px; font-weight: bold; }
div.side-menu-item a:hover { background: #f5f5f5; border-right: 3px solid #337ab7; }
div.side-menu-item a.active { border-right: 3px solid #337ab7; }

#sidebar-content .dropdown-menu { width: 100%; }
#create-point-network-label,
#create-point-format-label { line-height: 34px; margin: 0; }
#create-point-network,
#create-point-format { width: 150px; float: right; }
#create-point-address-input + span .btn-default { color: #2e6da4; }

.glyphicon-spin { -webkit-animation: spin 3000ms infinite linear; animation: spin 3000ms infinite linear; }
.glyphicon-spin.disabled { opacity: 0.66; }
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); transform: rotate(359deg); }
}
@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); transform: rotate(359deg); }
}

.select-icon { background-image: url(../images/select-sprite.png); display: inline-block; width: 15px; height: 15px; margin-top: 3px; }
.select-icon.select-click    { background-position: 0 0; }
.select-icon.select-rect     { background-position: 0 -15px; }
.select-icon.select-circle   { background-position: 0 -30px; }
.select-icon.select-lasso    { background-position: 0 -45px; }
.select-icon.mode-toggle     { background-position: 0 -60px; }
.select-icon.mode-add        { background-position: 0 -75px; }
.select-icon.mode-remove     { background-position: 0 -90px; }
.select-icon.clear-selection { background-position: 0 -105px; }
.select-icon.select-all      { background-position: 0 -120px; }
#selection-options { line-height: 35px; }

.selection-toggle { cursor: crosshair; }
.selection-add    { cursor: crosshair; }
.selection-remove { cursor: crosshair; }

#selection-summary { clear: both; line-height: 1.2em; font-size: 90%; background: #f4f4f4; color: #777; padding: 7.5px; margin-top: 15px; }

#selection-btn-group { margin-top: 15px; }
#selection-btn-group > .btn-group { width: 100%; margin-bottom: 5px; text-align: left; }
#selection-btn-group .btn { text-align: left; }
#selection-btn-group .btn .caret { position: absolute; display: block; top: 12.5px; right: 15px; }

.dropdown-menu a.disabled,
.dropdown-menu a.disabled:hover { color: #999; }

.secto-color { display: none; width: 8px; height: 16px;  vertical-align: bottom; }
#secto-modes-container label { font-size: 14px; font-weight: normal; display: block; }
#secto-modes-container label small { color: #999; }
#secto-modes-container td { line-height: 12px; }
#secto-modes-container table { margin-top: 6px; margin-bottom: 12px; }
#secto-modes-container .secto-count { margin-top: 2px; display: inline-block; padding: 3px 6px; height: 20px; min-width: 36px; text-align: center; text-shadow: #000 0px 0px 3px; color: #fff; }
#secto-modes-container input[type=radio],
#secto-modes-container small { transition: all 0.2s linear; display: inline-block; }
#secto-modes-container.auto-true input[type=radio] { opacity: 0; transform: translate(0, 15px); }
#secto-modes-container small { opacity: 0; transform: translate(0, -15px); }
#secto-modes-container.auto-true small { opacity: 1; transform: none; }
#secto-auto-container label.btn { padding: 5px; min-width: 85px; text-align: center; }
#secto-auto-container .if-value { display: none; }
#secto-modes-container.auto-null .if-value { display: none; }

#cache-infos { position: absolute; bottom: 0; left: 0; width: 310px; padding: 7.5px 15px; font-size: 85%; background: #f4f4f4; color: #999; }
#cache-infos a { float: right; display: inline-block; padding-right: 15px; color: #999; text-decoration: underline; }

/* carte */
.map-content { position: fixed; top: 0; bottom: 0; left: 0; right: 0; background: #f5f5f5; }
.map-sidebar.open + .map-content { left: 310px; }

.map-sidebar.open ~ .text-content { margin-left: 310px; padding: 15px;}

/* typeahead */
.typeahead,
.tt-query,
.tt-hint {
  padding: 8px 12px;
}

.typeahead {
  background-color: #fff;
}

.twitter-typeahead {
  width: 100%;
  padding-top: 4px;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}


.tt-hint {
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}

.gist {
}

/* Import backoffice */
.floatleft {
    float: left;
}
.floatright {
    float: right;
}
.fieldsetData {
    border:1px solid;
    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    width:530px;
    padding:5px;
}

.fieldsetGroup {
    border:1px solid #999999;

    -moz-border-radius:4px;
    -webkit-border-radius:4px;
    border-radius:4px;
    width:550px;
    padding:15px;
    margin:10px;
}
.fieldsetGroup legend
{
  margin-bottom:0px;
  width:auto;
  border-style:none;
  font-size:18px;
  font-weight: bold;
}
.verticalAlign {
  vertical-align: middle;
}
.tableImport {
  vertical-align: middle;
  width:540px;
  padding:5px;
}
.clearer {height:0px; clear:both;}

.inputAlign {
line-height:50px;
}
.bold {
font-weight: bold;}


.tableImport .disabled {
  cursor:not-allowed;
}



/* notifications */
#flash-message  {
  width: 500px;
  margin: auto;
  /*margin-top:10px;*/ /* ne pas remettre car fait apparaitre un ascenseur sur les cartes. Si besoin, simuler avec un conteneur supplémentaire et un padding */
  padding:10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 5px center;
  border-radius:4px;
  position:relative;
}

.warning {
    background-image: url(../images/icon_warning.png);
    background-repeat:no-repeat;
    padding-left: 40px;
    margin-left:10px;
}